import { toast } from 'react-toastify'

import { getIPAddress } from './getIPAddress'
import { getDevConfig } from './api.development'

export function api() {
  let baseApi = ''
  let authUrl = ''

  if (window.config) {
    baseApi = window.config.apiUrl ?? ''
    // baseApi = `https://pro.api.dev.logicstars.ru/api/`
    authUrl = window.config.authUrl ?? ''
  }

  let getToken = () => {
    return localStorage.getItem('token')
  }
  let setToken = (token) => {
    localStorage.setItem('token', token)
  }

  let getFileWithLoggined = (url) => {
    return innerCall('GET', url, null, 'AccessToken', true)
  }
  let getWithLoggined = (url) => {
    return innerCall('GET', url, null, 'AccessToken')
  }
  let postWithLoggined = (url, data) => {
    return innerCall('POST', url, data, 'AccessToken')
  }
  let putWithLoggined = (url, data) => {
    return innerCall('PUT', url, data, 'AccessToken')
  }
  let deleteWithLoggined = (url, data) => {
    return innerCall('DELETE', url, data, 'AccessToken')
  }
  let get = (url) => {
    return innerCall('GET', url)
  }
  let post = (url, body) => {
    return innerCall('POST', url, body)
  }

  let innerCall = (method, url, body, tokenName = 'token', isFile = false) => {
    let call = (url, token) => {
      let initForFetch = {
        headers: {
          authorization: 'Bearer ' + token,
        },
        method: method,
      }

      if (
        (method === 'POST' || method === 'PUT') &&
        body !== undefined &&
        body !== null &&
        typeof body !== 'string'
      ) {
        const formData = new FormData()
        for (const name in body) {
          if (typeof body[name]) formData.append(name, body[name])
          else formData.append(name, body[name], body[name].name)
        }

        initForFetch.body = formData
      }

      if (
        (method === 'POST' || method === 'PUT') &&
        body !== undefined &&
        body !== null &&
        typeof body === 'string'
      ) {
        initForFetch.body = JSON.stringify(encodeURI(body))
        initForFetch.headers = {
          authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        }
      }

      if (isFile) {
        return fetch(url, initForFetch)
      }

      return fetch(url, initForFetch).then((response) => {
        if (response.status === 429) {
          if (window.isViewErrorInThisTab === undefined) {
            toast.error('Слишком большое количество запросов, пожалуйста, повторите попытку позже.')

            if (
              localStorage.getItem('AccessToken') &&
              localStorage.getItem('AccessToken').length < 200
            ) {
              localStorage.removeItem('AccessToken')
            }

            setTimeout(() => {
              window.location = window.location.href
            }, 1000)
          }
          window.isViewErrorInThisTab = true
          return
        }
        if (response.status === 503) {
          toast.error('Сервис временно недоступен')
          localStorage.removeItem('AccessToken')
          return []
        }
        if (response.status === 401 || response.status === 403) {
          toast.error('Неавторизованный доступ')
          localStorage.removeItem(tokenName)
          localStorage.removeItem('AccessToken')
          if (window.location.pathname !== '/') {
            window.location = '/'
          }
        }
        return response.json()
      })
    }

    if (
      getToken() === null ||
      getToken() === 'API calls quota exceeded! maximum admitted 3 per 1s.'
    ) {
      return fetch(authUrl)
        .then((response) => {
          return response.text()
        })
        .then((token) => {
          setToken(token)
          return call(url, localStorage.getItem(tokenName))
        })
    }
    return call(url, localStorage.getItem(tokenName))
  }

  return {
    getConfig: (domain) => {
      if (process.env.NODE_ENV === 'development') {
        return new Promise((resolve) => {
          const config = getDevConfig('chery');

          if (!config) {
            throw Error('Не найден локальный конфиг дистрибьютора. См. api.js и api.development.js')
          }

          return resolve(config);
        });
      }

      return fetch(
        `https://${window.location.hostname}/api/config/getConfig?domain=${domain}`
      ).then((r) => {
        return r.json()
      })
    },
    getSARACards: () => {
      return getWithLoggined(baseApi + 'Cars/SARACards')
    },
    getUrlForRateMaster: (dealerId, orderNumber) => {
      return get('/api/urlForRateMaster?dealerId=' + dealerId + '&orderNumber=' + orderNumber)
    },
    getFaq: () => {
      return get(baseApi + 'Faq?setId=1')
    },
    registerPrivilegesRequest: (userDescr, userGender, userId) => {
      return get(
        '/api/registerPrivilegesRequest?userDescr=' +
        userDescr +
        '&userGender=' +
        userGender +
        '&userId=' +
        userId
      )
    },
    getMessageAboutEmptyLogin: () => {
      return get('/api/embeddedContent/messageAboutEmptyLogin?token=' +
        localStorage.getItem('AccessToken')
      )
    },
    deleteCar: (carId) => {
      return deleteWithLoggined(baseApi + 'Cars?cid=' + carId)
    },
    changeCarInfo: (carId, newNumber, newTransponderNumber, newTransponderDogovorNumber) => {
      return putWithLoggined(
        `${baseApi}Cars?cid=${carId}&licPlate=${newNumber}&transponderNumber=${newTransponderNumber}&transponderDogovorNumber=${newTransponderDogovorNumber}`
      )
    },
    getEmbeddedContent: () => {
      return get(
        '/api/embeddedContent/news?token=' + localStorage.getItem('AccessToken')
      )
    },
    setEmbeddedContent: (content) => {
      return post('/api/embeddedContent/news', {
        token: localStorage.getItem('AccessToken'),
        content,
      })
    },
    getPersonalDataText: () => {
      return get(baseApi + 'PersonalData')
    },
    loginByMarker: (marker) => {
      return get(baseApi + 'Auth/LoginByMarker?marker=' + marker)
    },
    getNotificationsForMenu: () => {
      return getWithLoggined(baseApi + 'ClientNotifications')
    },
    login: (login, password) => {
      return get(baseApi + 'AccessToken?m=1&l=' + login + '&p=' + password)
    },
    trackAccess: (mode) => {
      return putWithLoggined(baseApi + 'TrackClientAccess', { mode: mode })
    },
    removeAccount: () => {
      return deleteWithLoggined(baseApi + 'Access')
    },
    changeLogin: (login) => {
      return putWithLoggined(baseApi + 'Login?nl=' + login)
    },
    changePassword: (password) => {
      return putWithLoggined(baseApi + 'Password?check=false&np=' + password)
    },
    setPersonalDataAgree: () => {
      return postWithLoggined(baseApi + 'PersonalData')
    },
    getUserInfo: () => {
      return getWithLoggined(baseApi + 'ClientInfo')
    },
    getLastNews: () => {
      return getWithLoggined(baseApi + 'News/GetLastNews?mode=1')
    },
    getAllNews: () => {
      return getWithLoggined(baseApi + 'News/GetAllNews?mode=1')
    },
    getNewsItem: (id) => {
      return getWithLoggined(baseApi + 'News/GetNewsFullInfo?newsId=' + id)
    },
    getCars: () => {
      return getWithLoggined(baseApi + 'Cars')
    },
    getServiceBook: (carId) => {
      return getFileWithLoggined(baseApi + 'DsbPrint?carid=' + carId)
    },
    getCurrentDealerInfo: () => {
      return getWithLoggined(baseApi + 'Contacts/GetDealerContacts')
    },
    getDealerMarketingActions: () => {
      return getWithLoggined(baseApi + 'Dealer/GetMarketingActions')
    },
    getDealerList: () => {
      return getWithLoggined(baseApi + 'Dealer/GetList')
    },
    getServiceHistory: (year) => {
      return getWithLoggined(baseApi + 'ServiceHistory?year=' + year)
    },
    getServiceHistoryInfo: (number) => {
      return getWithLoggined(baseApi + 'WorkOrderInfo?wid=' + number)
    },
    getAnswers: (number) => {
      return getWithLoggined(baseApi + 'SurvayInfoList?wid=' + number)
    },
    getSsiQuestions: (number) => {
      return getWithLoggined(baseApi + 'SurvayInfoSSIList?did=' + number)
    },
    setAnswers: (number, happyIndexData, answersData) => {
      return postWithLoggined(baseApi + 'SurvayInfoList', {
        wid: number,
        hid: happyIndexData,
        ad: answersData,
        ctid: 3,
      })
    },
    setSsiAnswers: async (number, happyIndexData, primaryAnswersData, answersData) => {
      return postWithLoggined(baseApi + 'SurvayInfoSSIList', {
        did: number,
        hid: happyIndexData,
        pad: primaryAnswersData,
        ad: answersData,
        ctid: 4,
        ip: await getIPAddress(),
      })
    },
    markAsRead: (id) => {
      return postWithLoggined(baseApi + 'News/MarkNews?newsId=' + id + '&mode=1')
    },
    markAllAsRead: () => {
      return postWithLoggined(baseApi + 'News/MarkNews?mode=1')
    },
    unMarkNewsItem: (id) => {
      return postWithLoggined(baseApi + 'News/UnmarkNews?newsId=' + id)
    },
    setMyDealer: (id) => {
      return putWithLoggined(baseApi + 'Dealer/ChangeDealer?dealerpointId=' + id)
    },
    getCurrentServiceRequest: () => {
      return getWithLoggined(baseApi + 'ServiceRecord')
    },
    deleteServiceRequest: () => {
      return deleteWithLoggined(baseApi + 'ServiceRecord')
    },
    getServiceRequestTypes: () => {
      return getWithLoggined(baseApi + 'ServiceRecordCategoryList')
    },
    getChats: (year) => {
      return getWithLoggined(baseApi + 'MasterMessageList?year=' + year)
    },
    getChatMessages: (id) => {
      return getWithLoggined(baseApi + 'SubMessageList?fid=' + id)
    },
    sendMessage: (id, message) => {
      return postWithLoggined(baseApi + 'SubMessage?fid=' + id, message)
    },
    createNewChatForDealer: (carId, directionTypeId, requestTypeId, requestSubTypeId, message) => {
      return postWithLoggined(
        baseApi +
        'MasterMessage/NewMessage?carid=' +
        carId +
        '&directionId=' +
        directionTypeId +
        '&mainRequestTypeId=' +
        requestTypeId +
        (requestSubTypeId !== null ? '&subRequestTypeId=' + requestSubTypeId : '') +
        '&firstContactTypeId=5',
        message
      )
    },
    createServiceRequest: (
      carId,
      licenceNumber,
      miliage,
      type,
      date,
      time,
      comment,
      isReplacementCarRequired
    ) => {
      return postWithLoggined(
        baseApi +
        `ServiceRecord?carid=${carId}&lp=${licenceNumber}&m=${miliage}&wd=${date}&wt=${time}&catid=${type}&contactTypeId=3&${
          isReplacementCarRequired === undefined || isReplacementCarRequired === null
            ? ''
            : `isReplacementCarRequired=${String(isReplacementCarRequired)}`
        }`,
        comment
      )
    },
    getChatAllTypesForNewChatMessage: () => {
      return getWithLoggined(baseApi + 'FeedbackSetData/GetFeedbackSetData')
    },
    register: (
      vin,
      contragentType,
      firstName,
      lastname,
      meddleName,
      phone,
      email,
      additionInfo,
      file1,
      file2
    ) => {
      return post(baseApi + 'AccessRequest', {
        VIN: vin,
        ContragentType: contragentType,
        Name1: lastname,
        Name2: firstName,
        Name3: meddleName,
        ContactPhone: phone,
        Email: email,
        Notes: additionInfo,
        IsSmsEnabled: 'True',
        IsEmailEnabled: 'True',
        file1: file1.current.files[0],
        file2: file2.current.files[0],
      })
    },
    restore: (captchaId, captchaCode, email) => {
      return post('api/restore', {
        captchaId: captchaId,
        captchaCode: captchaCode.toUpperCase(),
        email: email,
        urlDomain: window.location.origin,
        isTest: window.location.host !== 'lk.chery.ru',
      })
    },
    getExeederUrl: () => postWithLoggined(`${baseApi}Cars/GetExeederUrl`),
    addContactPerson: ({ name, surname, phone, email }) => postWithLoggined(
      `${baseApi}Contacts/AddContactPerson?name=${name}&surname=${surname}&phone=${phone}&email=${email}`
    ),
    getContactPersonsList: () => getWithLoggined(`${baseApi}Contacts/GetContactPersons`),
    deleteContactPerson: (contactPersonId) => deleteWithLoggined(
      `${baseApi}Contacts/DeleteContactPerson?contactPersonId=${contactPersonId}`
    ),
  }
}
