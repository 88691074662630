import './QuestionScaleInput.css';

export default function QuestionScaleInput({ value, onChange, isComplete, short = false }) {
  let values = new Array(short ? 5 : 10).fill().map((_, index) => index + 1);

  return (
    <div className={'question-scale-input' + (isComplete ? ' is-complete' : '')}>
      {values.map((valueItem, index) => (
        <div
          key={index}
          className={'scale-item scale-' + `${short ? valueItem * 2 : valueItem}` + (valueItem === value ? ' active' : '')}
          onClick={() => onChange(valueItem)}
        >
          {valueItem}
        </div>
      ))}
    </div>
  );
}
